import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
export default function ShowRemainingChatLimit({ chatLimits }) {
  if (!chatLimits) return;
  return (
    <Box sx={{ width: "100%", mt: 1 }}>
      <Typography
        textAlign={"center"}
        variant="subtitle2"
        sx={{
          mr: 2,
          color: chatLimits?.remainingNoChatMontly === 0 ? "red" : "#7d7d7d",
          fontSize: "0.75rem",
        }}
      >
        Monthly remaining chat: {chatLimits?.remainingNoChatMontly} /{" "}
        {chatLimits?.maxNoChatMontly}
      </Typography>
    </Box>
  );
}
