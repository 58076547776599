import React, { useEffect } from "react";
import DynamicChatList from "../../components/chat/DynamicChatList";
import EmptyChatbot from "./EmptyChatbot";
import { fetchSubscriptionDetails } from "../../features/chatbot/chatbotSlice";
import { useDispatch, useSelector } from "react-redux";
import SimpleBackdrop from "../../components/loader/simple-backdrop";

// const chatbots = [
//   {
//     _id: "668773180ebd9eeb425ea39b",
//     name: "nippon cloud info",
//     userId: "66838217a64f0812a65ff809",
//     description: "This is a simple description test data",
//     model: "text-embedding-3-small",
//     files: [
//       {
//         name: "ncc sample.pdf",
//         srcUrl:
//           "https://smart-ai-storage.s3.us-east-1.amazonaws.com/documents/66838217a64f0812a65ff809-1720152853024.pdf",
//         _id: "668773180ebd9eeb425ea3a0",
//       },
//     ],
//     createdAt: "2024-07-05T04:14:16.125Z",
//     updatedAt: "2024-07-05T04:14:16.125Z",
//     __v: 0,
//   },
// ];

const chatbots = [];

export default function Chatbots() {
  const dispatch = useDispatch();
  const { subscriptionDetails } = useSelector((state) => state.chatbot);
  const { loading, subscriptionDetail } = subscriptionDetails;

  console.log(
    "--------|| Subscription details || --------------",
    subscriptionDetails
  );

  useEffect(() => {
    dispatch(fetchSubscriptionDetails());
  }, []);

  return (
    <div>
      {loading && <SimpleBackdrop />}
      {subscriptionDetail?.subscription ? (
        <DynamicChatList />
      ) : (
        <EmptyChatbot />
      )}
    </div>
  );
}
