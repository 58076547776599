import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import SaveIcon from "@mui/icons-material/Save";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import DropZoneFileUpload from "../../../upload/drop-zone-file-upload";
import axiosInstance from "../../../../api/axiosInstance";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import {
  fetchChatbotList,
  fetchChatbotAndFileLimitDetails,
} from "../../../../features/chatbot/chatbotSlice";
import SimpleBackdrop from "../../../loader/simple-backdrop";

export default function CreateChatbotModal({ open, handleChangeModal }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [selectedfile, setSelectedFile] = React.useState(null);
  const [name, setName] = React.useState("");
  const [initialText, setInitialText] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleFileSelect = (file) => {
    setSelectedFile(file);
  };

  const handleClose = () => {
    handleChangeModal(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      // Initialize form data
      const formData = new FormData();
      formData.append("file", selectedfile); // Append the selected file
      formData.append("name", name); // Append the chatbot name
      formData.append("initialText", initialText); // Append the chatbot name
      formData.append("description", description); // Append the chatbot description

      // Ensure all fields are filled
      if (
        !selectedfile ||
        !formData.get("name") ||
        !formData.get("description")
      ) {
        setLoading(false);
        // Show notification for missing fields (use your notification method)
        return enqueueSnackbar("Please filled up all input.", {
          variant: "warning",
        });
      }

      //------------------|| CHECK INPUT TEXT LENGTH ||------------------
      if (name.length > 200)
        return enqueueSnackbar("Name is too long.", {
          variant: "warning",
        });

      if (description.length > 500)
        return enqueueSnackbar("Description is too long.", {
          variant: "warning",
        });

      if (initialText.length > 200)
        return enqueueSnackbar("Initial text is too long.", {
          variant: "warning",
        });

      // Post the form data to the server
      await axiosInstance.post("/user/chatbot/create", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Handle success response
      enqueueSnackbar("Chatbot created successfully", { variant: "success" });

      dispatch(fetchChatbotList());
      dispatch(fetchChatbotAndFileLimitDetails());

      handleClose();

      // Show success notification (use your notification method)
    } catch (error) {
      // Handle error response
      console.error("Failed to create chatbot", error);
      enqueueSnackbar(
        error?.response?.data ||
          error?.response?.data?.message ||
          "Failed to create chatbot.",
        { variant: "error" }
      );
      // Show error notification (use your notification method)
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      {loading && <SimpleBackdrop />}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Create a new Chatbot"}
        </DialogTitle>
        <DialogContent>
          <Paper sx={{ p: { md: 4, xs: 2 }, mb: 2 }} variant="outlined" square>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "100%" },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  required
                  id="outlined-required"
                  label="Name"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  placeholder="Chatbot name"
                />
              </div>
              <div>
                <TextField
                  id="outlined-required"
                  label="Initial chat"
                  value={initialText}
                  onChange={(event) => setInitialText(event.target.value)}
                  placeholder="Hi, how can i help you?"
                />
              </div>
              <div>
                <TextField
                  id="outlined-multiline-static"
                  label="Description"
                  multiline
                  rows={3}
                  placeholder="Chatbot instruction"
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                />
              </div>
            </Box>
          </Paper>

          <DropZoneFileUpload
            file={selectedfile}
            handleFileSelect={handleFileSelect}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            onClick={handleSubmit}
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="outlined"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
