export const baseURL = "https://nccbotapi.marketbridgejapan.com"; // "http://localhost:3000";

export const faqData = [
  {
    question: "Are the payments for the chatbot services refundable?",
    answer:
      "No, all payments made for our chatbot services are non-refundable. We recommend reviewing our services thoroughly before making a purchase.",
  },
  {
    question:
      "What are the terms and conditions for using your chatbot services?",
    answer:
      "By using our chatbot services, you agree to abide by our terms and conditions, which include but are not limited to compliance with applicable laws, not using the service for illegal activities, and not distributing harmful content. Please review our full terms and conditions on our website for more details.",
  },
  {
    question: "How do you handle data privacy for the chatbot services?",
    answer:
      "We take data privacy very seriously. All user data is encrypted and stored securely. We do not share your personal information with third parties without your consent, except as required by law. For more information, please refer to our privacy policy.",
  },
  {
    question: "How can I manage my account details?",
    answer:
      "You can manage your account details by logging into your account on our website. From there, you can update your personal information, change your password, and manage your subscription.",
  },
  {
    question: "What subscription plans do you offer for the chatbot services?",
    answer:
      "We offer three subscription plans: Basic, Standard, and Premium. Each plan offers different features and limits to suit various needs. Please visit our pricing page for detailed information on each plan.",
  },
  {
    question: "How can I get support for issues with the chatbot services?",
    answer:
      "If you encounter any issues or have questions about our chatbot services, you can contact our support team via email at support@example.com or through the live chat feature on our website. Our team is available 24/7 to assist you.",
  },
  {
    question: "What features are included in your chatbot services?",
    answer:
      "Our chatbot services include features such as natural language processing, file search, question and answer capabilities, and integration with various databases. Depending on your subscription plan, additional features may be available.",
  },
  {
    question: "What security measures are in place for the chatbot services?",
    answer:
      "We implement robust security measures to protect your data, including encryption, secure access controls, and regular security audits. Your data is protected from unauthorized access and breaches.",
  },
  {
    question: "Can the chatbot services be integrated with other systems?",
    answer:
      "Yes, our chatbot services can be integrated with various systems such as CRM platforms, e-commerce websites, and other business applications. Please contact our sales team for more information on integration options.",
  },
  {
    question: "Is there a trial period available for your chatbot services?",
    answer:
      "Yes, we offer a 14-day trial period for our chatbot services. During this period, you can explore the features and decide if our services meet your needs before committing to a subscription.",
  },
];

export const formatDate = (date) => {
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    // hour: '2-digit',
    // minute: '2-digit',
    // second: '2-digit',
  }).format(new Date(date));
};
