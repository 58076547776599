import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { RiSettings4Line } from "react-icons/ri";
import Fab from "@mui/material/Fab";
import UserProfileDialog from "../profile/user-profile";
import axiosInstance from "../../api/axiosInstance";
import SimpleBackdrop from "../loader/simple-backdrop";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import useAuth from "../../auth/useAuth";
import SubscriptionModal from "../dialog/SubscriptionModal";

export default function UserMenuItem({ openDrawer }) {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [openSubscriptionModal, setOpenSubscriptionModal] =
    React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [openProfileDialog, setOpenProfileDialog] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    const isLogout = logout();
    if (isLogout) navigate("/signin");
  };

  const handleManageSubscription = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        "/payment//create-customer-portal-session"
      );
      handleClose();
      window.location.href = `${response.data.url}`;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.error || "Something went wrong", {
        variant: "error",
      });
    }
    setLoading(false);
  };
  return (
    <div>
      {loading && <SimpleBackdrop />}

      {openSubscriptionModal && (
        <SubscriptionModal
          open={openSubscriptionModal}
          handleClose={() => setOpenSubscriptionModal(false)}
        />
      )}

      {openProfileDialog && (
        <UserProfileDialog
          open={openProfileDialog}
          handleClose={() => setOpenProfileDialog(false)}
        />
      )}
      <Fab
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          width: "100%",
          background: "#000000c9",
          color: "white",
          textTransform: "none", // prevents text from being uppercased
          "&:hover": {
            background: "#000000c9", // ensure background color doesn't change on hover
            color: "white", // ensure text color doesn't change on hover
          },
        }}
        variant="extended"
        size="small"
      >
        {/* <LocalOfferIcon sx={{ mr: 1 }} /> */}
        <RiSettings4Line style={{ marginRight: "8px", fontSize: "1rem" }} />
        {openDrawer && "Manage"}
      </Fab>

      <Menu
        id="basic-menu"
        sx={{
          "& .MuiPaper-root": {
            minWidth: "200px",
            display: "flex",
            justifyContent: "center",
          },
          "& .MuiMenuItem-root": {
            display: "flex",
            justifyContent: "center",
          },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            setOpenProfileDialog(true);
            handleClose();
          }}
        >
          Profile
        </MenuItem>
        <MenuItem onClick={handleManageSubscription}>
          Manage Subscription
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenSubscriptionModal(true);
            handleClose();
          }}
        >
          Subscription
        </MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </div>
  );
}
