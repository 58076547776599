import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Paper from "@mui/material/Paper";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import axiosInstance from "../../../../api/axiosInstance";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { fetchChatbotList } from "../../../../features/chatbot/chatbotSlice";

export default function ChatbotEdit({ open, handleChangeModal, chatbotId }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { chatbot } = useSelector((state) => state.chatbot);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(chatbot.name || "");
  const [initialText, setInitialText] = useState(chatbot.initialText || "");
  const [description, setDescription] = useState(chatbot.description || "");
  const [domains, setDomains] = useState(chatbot.allowedDomains || [""]);
  const [isLocked, setIsLocked] = useState(chatbot.isLocked || false);

  useEffect(() => {
    if (chatbot.chatbots) {
      const chatbotData = chatbot.chatbots.find((bot) => bot._id === chatbotId);
      if (chatbotData) {
        setName(chatbotData.name);
        setDescription(chatbotData.description);
        setInitialText(chatbotData.initialText || "");
        setDomains(
          chatbotData.allowedDomains.length > 0
            ? chatbotData.allowedDomains
            : [""]
        );
        setIsLocked(chatbotData.isLocked);
      }
    }
  }, [chatbot, chatbotId]);

  const handleClose = () => {
    handleChangeModal(false);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      //------------------|| CHECK INPUT TEXT LENGTH ||------------------
      if (name.length > 200)
        return enqueueSnackbar("Name is too long.", {
          variant: "warning",
        });

      if (description.length > 500)
        return enqueueSnackbar("Description is too long.", {
          variant: "warning",
        });

      if (initialText.length > 200)
        return enqueueSnackbar("Initial text is too long.", {
          variant: "warning",
        });

      await axiosInstance.put(`/user/chatbot/update/${chatbotId}`, {
        name,
        initialText,
        description,
        allowedDomains: domains,
        isLocked,
      });
      enqueueSnackbar("Chatbot updated successfully.", { variant: "success" });
      handleClose();
      dispatch(fetchChatbotList());
    } catch (error) {
      enqueueSnackbar(
        error?.response?.data?.error || "Failed to update chatbot.",
        { variant: "error" }
      );
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Update chatbot"}
        </DialogTitle>
        <DialogContent>
          <Paper
            sx={{
              p: { md: 8, xs: 2 },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            variant="outlined"
            square
          >
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": {
                  m: 1,
                  minWidth: { md: 400, xs: 200 },
                },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  required
                  id="outlined-required"
                  label="Name"
                  placeholder="Chatbot name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div>
                <TextField
                  id="outlined-required"
                  label="Initial Text"
                  placeholder="Hi, how can i help you?"
                  value={initialText}
                  onChange={(e) => setInitialText(e.target.value)}
                />
              </div>

              <div>
                <TextField
                  id="outlined-multiline-static"
                  label="Description"
                  multiline
                  rows={3}
                  placeholder="Chatbot instruction"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </Box>
          </Paper>
          <FormControlLabel
            sx={{ mt: 1, color: "red" }}
            control={
              <Checkbox
                checked={isLocked}
                onChange={(e) => setIsLocked(e.target.checked)}
              />
            }
            label="Do you want to disable this chatbot?"
          />
        </DialogContent>
        <DialogActions>
          <Button sx={{ color: "black" }} autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            sx={{ color: "black" }}
            onClick={handleSave}
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="outlined"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
