import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SellIcon from "@mui/icons-material/Sell";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { underScoreTitle } from "../../helper/titleHelper";
// import aiBotImg from "../../images/ai-bot.webp";
import aiBotImg from "../../images/smart-ai-logo.png";
import DynamicChatBox from "./chatbox/dynamic-chatbox";
import Fab from "@mui/material/Fab";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CreateChatbotModal from "./chatbox/sub/create-chatbot-modal";
import UserMenuItem from "../menu/UserMenuItem";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchChatbotAndFileLimitDetails,
  fetchChatbotList,
} from "../../features/chatbot/chatbotSlice";
import { formatDate } from "../../constants/index";
import Testimonials from "../../pages/chat/landing-page/components/Testimonials";
import getLPTheme from "../../pages/chat/landing-page/getLPTheme";
import axiosInstance from "../../api/axiosInstance";
import { enqueueSnackbar } from "notistack";
import SimpleBackdrop from "../loader/simple-backdrop";
import useAuth from "../../auth/useAuth";
import ChatbotListItems from "./ChatbotListItems";
import ShowLimitsView from "./chatbox/sub/show-chatbot-limit";
const drawerWidth = 240;
const mode = "light";
const LPtheme = createTheme(getLPTheme(mode));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  backgroundColor: "#ebebeb", // Add background color
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  backgroundColor: "#ebebeb", // Add background color
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function DynamicChatList() {
  const { chatbot, subscriptionDetails } = useSelector(
    (state) => state.chatbot
  );
  const dispatch = useDispatch();
  const { logout } = useAuth();
  const [openChatbotModal, setOpenChatbotModal] = React.useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const [selectdChatbot, setSelectedChatbot] = React.useState(null); // React.useState(chatbots[0]);
  const [chatbots, setChatbots] = React.useState([]);
  const [subscription, setSubscription] = React.useState(null);

  const handleManageSubscription = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        "/payment//create-customer-portal-session"
      );

      window.location.href = `${response.data.url}`;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.error || "Something went wrong", {
        variant: "error",
      });
    }
    setLoading(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    if (logout()) navigate("/signin");
  };

  const sideBarCreateBotUi = (
    <Box
      sx={{
        position: "absolute",
        bottom: 20,
        left: 5,
        right: 5,
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Typography
        textAlign={"center"}
        variant="overline"
        display="block"
        gutterBottom
      >
        Want to create more chatbot ?
      </Typography>
      <Fab
        onClick={() => setOpenChatbotModal(true)}
        sx={{
          width: "100%",
          background: "#000000c9",
          color: "white",
          textTransform: "none", // prevents text from being uppercased
          "&:hover": {
            background: "#000000c9", // ensure background color doesn't change on hover
            color: "white", // ensure text color doesn't change on hover
          },
        }}
        variant="extended"
        size="small"
        color="primary"
      >
        <AddCircleIcon sx={{ mr: 1 }} />
        {open && "Create a new bot"}
      </Fab>
    </Box>
  );

  React.useEffect(() => {
    if (subscriptionDetails.subscriptionDetail) {
      const { expireDate, status } =
        subscriptionDetails.subscriptionDetail.subscription.user.subscription;
      const { title } =
        subscriptionDetails.subscriptionDetail.subscription.plan;

      setSubscription({ expireDate, status, title });
    }
  }, [subscriptionDetails]);

  const subscriptionSidebarUI = (
    <Box
      sx={{
        p: 2,
        position: "absolute",
        bottom: 100,
        left: 5,
        right: 5,
        marginLeft: "auto",
        marginRight: "auto",
        border: "1px dashed #b4bac5",
        borderRadius: "12px",
        m: { xs: 0, md: 1 },
      }}
    >
      <UserMenuItem openDrawer={open} />
      {subscription && open && (
        <div>
          <Typography
            sx={{ mt: 1, fontSize: "12px" }}
            fontWeight={600}
            variant="subtitle1"
          >
            {subscription && subscription.title}
          </Typography>
          {subscription.status === "canceled" && (
            <Typography
              sx={{ fontSize: "12px", color: "red" }}
              fontWeight={500}
              variant="subtitle1"
            >
              subscription is canceled
            </Typography>
          )}
          {new Date(subscription?.expireDate) < new Date() ? (
            <Typography
              sx={{ fontSize: "12px", color: "red" }}
              fontWeight={500}
              variant="subtitle1"
            >
              subscription is expired <br />
              <span style={{ color: "#075099" }}>
                please renew subscription{" "}
                <IconButton
                  onClick={handleManageSubscription}
                  sx={{ color: "#075099" }}
                  size="small"
                >
                  <SellIcon sx={{ fontSize: "1rem" }} />
                </IconButton>
              </span>
            </Typography>
          ) : (
            <Typography
              sx={{ fontSize: "12px" }}
              fontWeight={600}
              variant="subtitle1"
            >
              Renew plan {formatDate(subscription?.expireDate)}
            </Typography>
          )}
          <ShowLimitsView />
        </div>
      )}
    </Box>
  );

  React.useEffect(() => {
    if (chatbot.chatbots) setChatbots(chatbot.chatbots);
  }, [chatbot]);

  React.useEffect(() => {
    dispatch(fetchChatbotList());
    dispatch(fetchChatbotAndFileLimitDetails());
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      {loading && <SimpleBackdrop />}
      {openChatbotModal && (
        <CreateChatbotModal
          open={openChatbotModal}
          handleChangeModal={(state) => setOpenChatbotModal(state)}
        />
      )}
      <CssBaseline />
      <AppBar
        sx={{ boxShadow: "none", bgcolor: "#ebebeb" }}
        position="fixed"
        open={open}
      >
        <Toolbar
          sx={{
            bgcolor: "#ebebeb",
            boxShadow: "none !important",
            borderBottom: "1px solid #1f1f1f33",
          }}
          maxWidth="xl"
        >
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            sx={{ color: "black" }}
            variant="h6"
            noWrap
            component="div"
          >
            SMART AI {selectdChatbot && "/"}{" "}
            {chatbot && underScoreTitle(selectdChatbot?.name)}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <IconButton onClick={() => navigate("/guide")}>
              <Typography
                sx={{ mr: 2, color: "black" }}
                fontSize="1rem"
                fontWeight={600}
              >
                Guide
              </Typography>
            </IconButton>
            <IconButton onClick={handleLogout}>
              <LogoutIcon sx={{ fontSize: "2rem", color: "black" }} />
            </IconButton>
          </Box>
          {/* <Box sx={{ position: "absolute", right: "30px" }}>
            <IconButton onClick={handleLogout}>
              <LogoutIcon sx={{ fontSize: "2rem", color: "white" }} />
            </IconButton>
          </Box> */}
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <ChatbotListItems
          open={open}
          chatbots={chatbots}
          selectdChatbot={selectdChatbot}
          setSelectedChatbot={(chatbot) => setSelectedChatbot(chatbot)}
        />
        {subscriptionSidebarUI}
        {sideBarCreateBotUi}
        <Box sx={{ position: "absolute", mb: 4, ml: 10 }}>
          <img src={aiBotImg} alt="ncc bot" style={{ maxWidth: "50px" }} />
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {selectdChatbot && (
          <DynamicChatBox
            chatbot={selectdChatbot}
            initialText="SMART AI が会話に参加しました。"
            type="CHAT"
          />
        )}
        {!selectdChatbot && (
          <ThemeProvider theme={LPtheme}>
            <CssBaseline />
            <Box sx={{ bgcolor: "background.default", mt: -16 }}>
              <Testimonials />
            </Box>
          </ThemeProvider>
        )}
      </Box>
    </Box>
  );
}
