import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import animatedBotSrc from "../../../../images/animated-bot/bot-01.gif";
import { useTheme } from "@mui/system";

const chatbotGuidelines = [
  {
    step: 1,
    title: "Getting Started",
    description:
      "To begin using the chatbot, simply click on the chat icon located at the bottom right corner of the screen. This will open the chat window where you can start interacting with the bot.",
  },
  {
    step: 2,
    title: "Initiating a Conversation",
    description:
      "Start by typing a greeting or your initial query. For example, you can say 'Hello' or ask a specific question like 'What are your operating hours?' The chatbot will respond accordingly.",
  },
  {
    step: 3,
    title: "Using Predefined Commands",
    description:
      "The chatbot recognizes several predefined commands to help you navigate quickly. For instance, typing 'Help' will display a list of available commands and features. Use 'Menu' to see the main options.",
  },
  {
    step: 4,
    title: "Asking for Information",
    description:
      "You can ask the chatbot for various types of information, such as 'What is the status of my order?' or 'How do I reset my password?' The chatbot will provide you with the relevant details or guide you through the necessary steps.",
  },
  {
    step: 5,
    title: "Providing Feedback",
    description:
      "After receiving assistance from the chatbot, you can provide feedback by typing 'Feedback' followed by your comments. This helps us improve the chatbot's performance and service quality.",
  },
  {
    step: 6,
    title: "Escalating to Human Support",
    description:
      "If the chatbot is unable to assist you with your query, you can type 'Talk to a human' to be connected with a customer support representative. They will take over the conversation and provide further assistance.",
  },
  {
    step: 7,
    title: "Ending the Chat",
    description:
      "To end the conversation, simply type 'Goodbye' or close the chat window. The chatbot will log the conversation for future reference, ensuring that your queries are documented.",
  },
  {
    step: 4,
    title: "Asking for Information",
    description:
      "You can ask the chatbot for various types of information, such as 'What is the status of my order?' or 'How do I reset my password?' The chatbot will provide you with the relevant details or guide you through the necessary steps.",
  },
  {
    step: 6,
    title: "Escalating to Human Support",
    description:
      "If the chatbot is unable to assist you with your query, you can type 'Talk to a human' to be connected with a customer support representative. They will take over the conversation and provide further assistance.",
  },
];

const whiteLogos = [
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628e8573c43893fe0ace_Sydney-white.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d520d0517ae8e8ddf13_Bern-white.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f46794c159024c1af6d44_Montreal-white.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e891fa22f89efd7477a_TerraLight.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a09d1f6337b1dfed14ab_colorado-white.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5caa77bf7d69fb78792e_Ankara-white.svg",
];

const darkLogos = [
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628889c3bdf1129952dc_Sydney-black.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d4d8b829a89976a419c_Bern-black.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f467502f091ccb929529d_Montreal-black.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e911fa22f2203d7514c_TerraDark.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a0990f3717787fd49245_colorado-black.svg",
  "https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5ca4e548b0deb1041c33_Ankara-black.svg",
];

const logoStyle = {
  width: "64px",
  opacity: 0.3,
};

export default function Testimonials() {
  const theme = useTheme();
  const logos = theme.palette.mode === "light" ? darkLogos : whiteLogos;

  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 4 },
        pb: { xs: 8, sm: 8 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          mt: 8,
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        {/* <Typography component="h2" variant="h4" color="text.primary">
          Guideline
        </Typography> */}
        <img
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: "200px",
          }}
          src={animatedBotSrc}
          alt="ncc smart ai"
        />
        <Typography variant="body1" color="text.secondary">
          Below are guidelines of our chatbot. Use ncc smart ai most interective
          features and grow your business.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {chatbotGuidelines.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: "flex" }}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                flexGrow: 1,
                p: 1,
              }}
            >
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {item.description}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  pr: 2,
                }}
              >
                <Typography
                  component="span"
                  variant="h5"
                  sx={{
                    ml: 2,
                    textAlign: "center",
                    fontSize: "clamp(1rem, 5vw, 1rem)",
                    color: (theme) =>
                      theme.palette.mode === "light"
                        ? "primary.main"
                        : "primary.light",
                  }}
                >
                  {item.title}
                </Typography>

                <Button variant="contained" color="primary">
                  Details
                </Button>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
