import React, { useState } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IoChatboxEllipses } from "react-icons/io5";
import ChatbotSettings from "../chat/chatbox/sub/chat-bot-settings";
import ChatbotEdit from "./chatbox/sub/chat-bot-edit";
import ConfirmDialog from "../dialog/ConfirmDialog";
import { useDispatch } from "react-redux";
import {
  fetchChatbotAndFileLimitDetails,
  fetchChatbotList,
} from "../../features/chatbot/chatbotSlice";
import axiosInstance from "../../api/axiosInstance";
import { useSnackbar } from "notistack";
import SimpleBackdrop from "../loader/simple-backdrop";
import Typography from "@mui/material/Typography";

export default function CustomListItem({
  open,
  chatBot,
  selectdChatbot,
  setSelectedChatbot,
}) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClick = (event) => {
    event.stopPropagation(); // Prevent list item click
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event?.stopPropagation(); // Prevent list item click
    setAnchorEl(null);
  };

  const handleEdit = () => {
    handleClose();
    setOpenEditModal(true);
  };

  const handleSettings = () => {
    handleClose();
    setOpenSettingsModal(true);
  };

  const handleDelete = () => {
    handleClose();
    setConfirmDialogOpen(true);
  };

  const handleDeleteChatbot = async () => {
    setLoading(true);
    try {
      await axiosInstance.delete(`/user/chatbot/delete/${chatBot._id}`);
      enqueueSnackbar("Chatbot deleted successfully.", { variant: "success" });
      dispatch(fetchChatbotList());
      dispatch(fetchChatbotAndFileLimitDetails());
      setConfirmDialogOpen(false);
      setSelectedChatbot(null);
    } catch (error) {
      enqueueSnackbar(error.response?.data || error?.response?.error?.message, {
        variant: "error",
      });
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      {loading && <SimpleBackdrop />}
      {openSettingsModal && (
        <ChatbotSettings
          chatbotId={chatBot._id}
          open={openSettingsModal}
          handleChangeModal={(state) => setOpenSettingsModal(state)}
        />
      )}
      {openEditModal && (
        <ChatbotEdit
          chatbotId={chatBot._id}
          open={openEditModal}
          handleChangeModal={(state) => setOpenEditModal(state)}
        />
      )}
      {confirmDialogOpen && (
        <ConfirmDialog
          open={confirmDialogOpen}
          handleClose={() => setConfirmDialogOpen(false)}
          handleConfirm={handleDeleteChatbot}
          title="Confirm Delete"
          content="Are you sure you want to delete this chatbot and all its files? This action cannot be undone."
        />
      )}
      <ListItem
        selected={chatBot._id.toString() === selectdChatbot?._id}
        onMouseEnter={(e) =>
          (e.currentTarget.querySelector(".hoverIcon").style.visibility =
            "visible")
        }
        onMouseLeave={(e) =>
          (e.currentTarget.querySelector(".hoverIcon").style.visibility =
            "hidden")
        }
        disablePadding
        sx={{
          display: "block",
          color: chatBot._id.toString() === selectdChatbot?._id && "#1976d2",
          borderRight:
            chatBot._id.toString() === selectdChatbot?._id &&
            "3px solid #1976d2",
        }}
      >
        <ListItemButton
          onClick={() => setSelectedChatbot(chatBot)}
          sx={{
            justifyContent: open ? "initial" : "center",
            px: 2.5,
            maxHeight: "40px",
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "center",
              color: "black",
            }}
          >
            <IoChatboxEllipses
              fontSize={"1.5rem"}
              color={selectdChatbot?._id === chatBot._id && "#1976d2"}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                fontSize={"14px"}
                fontWeight={500}
                variant="subtitle1"
              >
                {chatBot?.name}
              </Typography>
            }
            sx={{
              opacity: open ? 1 : 0,
              color: "black",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "calc(100% - 40px)", // Adjust max width to account for the icon button
            }}
          />
          <div style={{ marginLeft: "auto", width: 40 }}>
            <IconButton
              className="hoverIcon"
              onClick={handleClick}
              sx={{ visibility: "hidden" }}
            >
              <MoreVertIcon />
            </IconButton>
          </div>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            sx={{
              "& .MuiPaper-root": {
                padding: { md: 2, xs: 1 },
                borderRadius: "10px",
              },
            }}
          >
            <MenuItem onClick={handleEdit}>Edit</MenuItem>
            <MenuItem onClick={handleSettings}>Settings</MenuItem>
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
          </Menu>
        </ListItemButton>
      </ListItem>
    </React.Fragment>
  );
}
