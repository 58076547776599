import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SnippetFolderIcon from "@mui/icons-material/SnippetFolder";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import Box from "@mui/material/Box";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import AllFileList from "./all-file-list";
import { Typography } from "@mui/material";

export default function ChatbotSubDetailsTab({ chatbotId }) {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="icon label tabs example"
          >
            <Tab
              icon={<SnippetFolderIcon sx={{ fontSize: 14 }} />}
              label={<Typography sx={{ fontSize: 12 }}>All Files</Typography>}
              value="1"
              sx={{ textTransform: "none", minHeight: 48 }}
            />
            <Tab
              icon={<FindInPageIcon sx={{ fontSize: 14 }} />}
              label={
                <Typography sx={{ fontSize: 12 }}>Matched Files</Typography>
              }
              value="2"
              sx={{ textTransform: "none", minHeight: 48 }}
            />
            <Tab
              icon={<LiveHelpIcon sx={{ fontSize: 14 }} />}
              label={<Typography sx={{ fontSize: 12 }}>Questions</Typography>}
              value="3"
              sx={{ textTransform: "none", minHeight: 48 }}
            />
          </Tabs>
        </Box>
        <TabPanel value="1">
          <AllFileList chatbotId={chatbotId} />
        </TabPanel>
        <TabPanel value="2">
          <Typography fontWeight={650} variant="subtitle1">
            Coming soon...
          </Typography>
        </TabPanel>
        <TabPanel value="3">
          <Typography fontWeight={650} variant="subtitle1">
            Coming soon...
          </Typography>
        </TabPanel>
      </TabContext>
    </Box>
  );
}
