import * as React from "react";
import { Chat } from "@progress/kendo-react-conversational-ui";
import menAvatarImg from "../../../images/men-avatar.jpg";
import PulseLoader from "react-spinners/PulseLoader";
import animatedBotSrc from "../../../images/animated-bot/bot-01.gif";
import "react-json-view-lite/dist/index.css";
import axiosInstance from "../../../api/axiosInstance";
import Grid from "@mui/material/Grid";
import ChatbotFileList from "./sub/chatbot-file-list";
import "./style.css";
import { enqueueSnackbar } from "notistack";
import ShowRemainingChatLimit from "./sub/show-remaining-chat-limit";
import { useSelector } from "react-redux";

const user = {
  id: 1,
  avatarUrl: menAvatarImg,
  avatarAltText: "SMART AI",
};

const bot = {
  id: 0,
};

const DynamicChatBox = ({
  chatbot,
  initialText = "Hello, this is ncc smart ai bot.",
  type,
}) => {
  const [messages, setMessages] = React.useState([
    {
      author: bot,
      timestamp: new Date(),
      text: initialText,
    },
  ]);
  const [loading, setLoading] = React.useState(false);
  const [chatLimits, setChatLimits] = React.useState({
    maxNoChatMontly: null,
    remainingNoChatMontly: null,
  });

  const addNewMessage = async (event) => {
    const newMessage = event.message;
    setMessages([...messages, newMessage]);

    if (!newMessage.attachment) {
      if (newMessage.text.length >= 200) {
        return enqueueSnackbar("Message is too long", { variant: "warning" });
      }

      try {
        setLoading(true);
        const response = await axiosInstance.post(`/user/chatbot/query`, {
          message: newMessage.text,
          // conversation: messages,
          chatbotId: chatbot._id,
        });
        const { maxNoChatMontly, remainingNoChatMontly } = response.data;
        setChatLimits({ maxNoChatMontly, remainingNoChatMontly });
        setLoading(false);

        const botResponse = {
          author: bot,
          text: response.data?.text || response.data?.message,
          timestamp: new Date(),
        };

        setMessages((prevMessages) => [...prevMessages, botResponse]);
      } catch (error) {
        enqueueSnackbar(error?.response?.data?.error || error?.response?.data, {
          variant: "error",
        });
        console.error("Error sending message to backend:", error);
      }
    }
  };

  const renderMessages = () => {
    if (loading) {
      const loadingMessage = {
        author: bot,
        timestamp: new Date(),
        text: <PulseLoader color="#1976d2" />, //"Writing.........✍",
      };
      return [...messages, loadingMessage];
    }

    return messages.map((message) =>
      message.template ? { ...message, text: message.template } : message
    );
  };

  React.useEffect(() => {
    if (chatbot) {
      const { maxNoChatMontly, remainingNoChatMontly } = chatbot;
      setChatLimits({ maxNoChatMontly, remainingNoChatMontly });
    }
  }, []);
  
  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="start"
      >
        <Grid className="chat-container" item xs={12} md={9}>
          <img className="chat-image" src={animatedBotSrc} alt="ai bot" />
          <div style={{ width: "100%" }}>
            <Chat
              messages={renderMessages()}
              user={user}
              onMessageSend={addNewMessage}
              placeholder={"Type a message..."}
              width={400}
            />
            <ShowRemainingChatLimit chatLimits={chatLimits} />
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          <ChatbotFileList chatbotId={chatbot._id} />
        </Grid>
      </Grid>
    </div>
  );
};

export default DynamicChatBox;
