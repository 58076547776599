export const subscriptionPlans = [
  {
    planId: "FREE_PLAN_01",
    priceId: "",
    title: "Free Plan",
    maxNoPdf: 1,
    maxFileSizeMB: 0.2,
    maxMessagePerMonth: 100,
    price: 0,
    durationInDays: 5,
    maxAllowedDomain: 0, // each chatbot
    textEmbeddingLLM_MODEL: "",
    embeddingChunk: 100,
    responseLLM_MODEL: "",
    description: [
      "5 Days free usage",
      "File upload 1 times only",
      "Max 2KB pdf file upload",
      "access admin panel",
      "normal chatbot",
    ],
    buttonVariant: "outlined",
  },
  {
    planId: "STANDARD_PLAN_01",
    priceId: "price_1PdkKPBZ2KM3Z6V03xTWPHYf",
    title: "Standard Plan",
    subheader: "Recommended",
    maxNoPdf: 4,
    maxFileSizeMB: 1,
    maxMessagePerMonth: 500,
    price: 2000, // Price in smallest currency unit (e.g., cents)
    durationInDays: 30,
    maxAllowedDomain: 2, // each chatbot
    textEmbeddingLLM_MODEL: "",
    embeddingChunk: 100,
    responseLLM_MODEL: "",
    description: [
      "Montly subscription",
      "Max 4 pdf file upload",
      "Pdf size maximum 1MB",
      "Montly 3 times can upload files",
      "Maximum 1000 chat montly",
      "access admin panel",
      "advanced chatbot",
    ],
    buttonVariant: "contained",
  },
  {
    planId: "PREMIUM_PLAN_01",
    priceId: "price_1PdkLBBZ2KM3Z6V0O1TpBIks",
    title: "Premium Plan",
    maxNoPdf: 10,
    maxFileSizeMB: 10,
    maxMessagePerMonth: 1000,
    price: 3000, // Price in smallest currency unit (e.g., cents)
    durationInDays: 30,
    maxAllowedDomain: 5, // each chatbot
    textEmbeddingLLM_MODEL: "",
    embeddingChunk: 100,
    responseLLM_MODEL: "",
    description: [
      "Montly subscription",
      "Max 10 pdf file upload",
      "Pdf size maximum 10 MB",
      "Montly 5 times can upload files",
      "Maximum 5000 chat montly",
      "access admin panel",
      "most advanced chatbot",
    ],
    buttonVariant: "outlined",
  },
  //   {
  //     planId: "",
  //     title: "Custom Plan",
  //     maxNoPdf: "Unlimited",
  //     maxFileSizeMB: "Unlimited",
  //     maxMessagePerMonth: "Unlimited",
  //     price: "Negotiable", // Price in smallest currency unit (e.g., cents)
  //     durationInDays: "Unlimited",
  //     maxAllowedDomain: "Unlimited", // each chatbot
  //     textEmbeddingLLM_MODEL: "",
  //     embeddingChunk: "Custome",
  //     responseLLM_MODEL: "",
  //     description: [
  //       "Pay for only usage",
  //       "Own domain domain, server and database",
  //       "Customize features",
  //       "Dedicated team support",
  //     ],
  //     buttonVariant: "outlined",
  //   },
];
